<template>
  <v-container>

  </v-container>
</template>

<script>
import FMaterial from "@/models/f-material";

export default {
  name: "FMaterialAccounting",
  props:{
    itemModified: new FMaterial(),
    itemsFDivision: [],
    itemsFPegawai:[],
  },
  methods:{
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
  }
}
</script>

<style scoped>

</style>