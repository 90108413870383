import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FMaterialMapService {
    getApiUrl() {
        return API_URL
    }
    getAllFMaterialMap(){
        return axios.get(API_URL + `getAllFMaterialMap`, { headers: authHeader() });
    }
    getAllFMaterialMapContaining(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFMaterialMapContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFMaterialMapById(id){
        return axios.get(API_URL + `getFMaterialMapById/${id}`, { headers: authHeader() });
    }
    getAllFMaterialMapByParent(id){
        return axios.get(API_URL + `getAllFMaterialMapByParent/${id}`, { headers: authHeader() });
    }
    getAllFMaterialMapByParentIds(ids){
        return axios.get(API_URL + `getAllFMaterialMapByParentIds/${ids}`, { headers: authHeader() });
    }

    updateFMaterialMap(item){
        return axios.put(API_URL + `updateFMaterialMap/${item.id}`, item, {headers: authHeader()})
    }
    createFMaterialMap(item){
        return axios.post(API_URL + `createFMaterialMap`, item, {headers: authHeader()})
    }
    deleteFMaterialMap(id){
        return axios.delete(API_URL + `deleteFMaterialMap/${id}`, {
            headers: authHeader()
        });
    }


}
export default new FMaterialMapService()