<template>
  <v-container>
        <div >

          <div class="subtitle-1 font-weight-bold mt-2 ml-2">Unit Of Mesurements (Satuan)</div>
          <v-divider></v-divider>
          <v-list class="ma-2 grey lighten-5">
            <v-list-item>
              <v-list-item-content>

                <v-row>
                  <v-col
                      cols="12"
                      sm="3"
                      md="3"
                  >
                    <v-text-field
                        v-model="itemModified.uom1"
                        label="UOM-1 (Biggest)"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="3"
                      md="3"
                  >
                    <v-text-field
                        v-model="itemModified.uom2"
                        label="UOM-2"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="3"
                      md="3"
                  >
                    <v-text-field
                        v-model="itemModified.uom3"
                        label="UOM-3"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="3"
                      md="3"
                  >
                    <v-text-field
                        v-model="itemModified.uom4"
                        label="UOM-4 (Smallest)"
                        dense
                    ></v-text-field>
                  </v-col>
                </v-row>

              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>

                <v-row >
                  <v-col
                      cols="12"
                      sm="3"
                      md="3"
                  >
                    <v-text-field
                        v-model="itemModified.convfact1"
                        label="Uom1 to Smallest"
                        type="number"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="3"
                      md="3"
                  >
                    <v-text-field
                        v-model="itemModified.convfact2"
                        label="Uom2 to Smallest"
                        type="number"
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="3"
                      md="3"
                  >
                    <v-text-field
                        v-model="itemModified.convfact3"
                        label="Uom3 to Smallest"
                        type="number"
                        dense
                    ></v-text-field>
                  </v-col>
                </v-row>

              </v-list-item-content>
            </v-list-item>
          </v-list>


          <div class="subtitle-1 font-weight-bold mt-2 ml-2">Prices</div>
          <v-divider></v-divider>
          <v-list class="ma-2 grey lighten-5">
            <v-list-item>
              <v-list-item-avatar color="grey lighten-4" class="justify-center align-center" size="50">
                  <span class="red--text subtitle-2">TAX</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-row>
                  <v-col
                      cols="12"
                      sm="3"
                      md="3"
                  >
                    <v-autocomplete
                        v-model="itemModified.ftaxBean"
                        :items="itemsFTax"
                        item-value="id"
                        item-text="description"
                        auto-select-first
                        dense
                        small-chips
                        deletable-chips
                        color="blue-grey lighten-2"
                        label="Tax"
                        persistent-hint
                        @change="ftaxBeanChange"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="3"
                      md="3"
                  >
                    <v-switch
                        v-model="itemModified.taxable"
                        :label="itemModified.taxable?'Tax':'Non'"
                        x-small
                        @change="taxableChange"
                        dense
                    ></v-switch>
                  </v-col>
                </v-row>

              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4"
                  >
                    <v-text-field
                        v-model="itemModified.pprice2AfterPpn"
                        label="H.Beli (After TAX)"
                        type="number"
                        persistent-hint
                        :hint="`DPP ${formattedCurrencyValue('', itemModified.pprice2)}`"
                        dense
                        prepend-icon="mdi-factory"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4"
                  >
                    <v-text-field
                        v-model="itemModified.sprice2AfterPpn"
                        label="Base Sprice to End User (Smallest after TAX)"
                        type="number"
                        persistent-hint
                        :hint="`DPP ${formattedCurrencyValue('', itemModified.sprice2)}`"
                        dense
                        prepend-icon="mdi-cart"
                    ></v-text-field>
                  </v-col>

                </v-row>

              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4"
                  >
                    <v-text-field
                        v-model="itemModified.sprice2ToDist1"
                        label="Sprice to Distributor (Smallest after TAX)"
                        type="number"
                        persistent-hint
                        v-if="haveRoleAdminMutlak"
                        dense
                    ></v-text-field>
<!--                    :hint="`DPP ${formattedCurrencyValue('', itemModified.sprice2ToDist1)}`"-->
                  </v-col>

                  <v-col
                      cols="12"
                      sm="4"
                      md="4"
                  >
                    <v-text-field
                        v-model="itemModified.sprice2ToDist2"
                        label="Sprice Reserved-1 (Smallest after TAX)"
                        type="number"
                        persistent-hint
                        v-if="haveRoleAdminMutlak"
                        dense
                    ></v-text-field>
<!--                    :hint="`DPP ${formattedCurrencyValue('', itemModified.sprice2ToDist2)}`"-->
                  </v-col>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4"
                  >
                    <v-text-field
                        v-model="itemModified.sprice2ToDist3"
                        label="Sprice Reserved-2 (Smallest after TAX)"
                        type="number"
                        persistent-hint
                        v-if="haveRoleAdminMutlak"
                        dense
                    ></v-text-field>
<!--                    :hint="`DPP ${formattedCurrencyValue('', itemModified.sprice2ToDist3)}`"-->
                  </v-col>


                </v-row>

              </v-list-item-content>
            </v-list-item>
          </v-list>

        </div>
  </v-container>
</template>

<script>

import FMaterial from "../../../models/f-material";

export default {
  name: "FMaterialUomAndPrice",
  props:{
    itemModified: new FMaterial(),
    itemsFTax:[]
  },
  data(){
    return{

      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    haveRoleAdminMutlak(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_ADMIN")  ){
          isValid=true
        }
      }
      return isValid
    },

  },
  methods: {
    ftaxBeanChange(item){
      console.log(item)
      if (this.itemModified.ftaxBean ===0 || this.itemModified.ftaxBean ===undefined|| this.itemModified.ftaxBean ===null ) {
        this.itemModified.taxable = false
      }else if (this.itemModified.ftaxBean >0){
        this.itemModified.taxable = true
      }
    },
    taxableChange(){
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },
  }

}
</script>

<style scoped>

</style>