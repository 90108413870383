import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FMaterialFileService {
    getApiUrl() {
        return API_URL
    }
    getAllFMaterialFile(){
        return axios.get(API_URL + `getAllFMaterialFile`, { headers: authHeader() });
    }
    getAllFMaterialFileContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFMaterialFile`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFMaterialFileContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFMaterialFileById(id){
        return axios.get(API_URL + `getFMaterialFileById/${id}`, { headers: authHeader() });
    }
    getAllFMaterialFileByParent(id){
        return axios.get(API_URL + `getAllFMaterialFileByParent/${id}`, { headers: authHeader() });
    }


    updateFMaterialFile(item){
        return axios.put(API_URL + `updateFMaterialFile/${item.id}`, item, {headers: authHeader()})
    }
    createFMaterialFile(item){
        return axios.post(API_URL + `createFMaterialFile`, item, {headers: authHeader()})
    }
    deleteFMaterialFile(id){
        return axios.delete(API_URL + `deleteFMaterialFile/${id}`, {
            headers: authHeader()
        });
    }

    deleteFMaterialFileAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteFMaterialFileAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }
    createFMaterialFileAvatar(item){
        return axios.post(API_URL + `createFMaterialFileAvatar`, item, {headers: authHeader()})
    }

    deleteAllFMaterialFile(itemIds){

        return axios.delete(API_URL + `deleteAllFMaterialFile`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FMaterialFileService()