<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >

      <v-card>
        <v-form  v-model="valid" ref="form">

          <v-toolbar
              dark
              color="brown"
              class="color-gradient-1"
              dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="8"
                    md="8"
                >
                  <v-autocomplete
                      label="Material/Product"
                      v-model="itemFMaterialMap.toMaterialBean"
                      :items="listFMaterial"
                      item-value="id"
                      item-text="pname"
                      auto-select-first
                      dense
                      small-chips
                      deletable-chips
                      color="blue-grey lighten-2"
                      outlined
                      :hint="`${lookupFMaterial(itemFMaterialMap.toMaterialBean).pcode}`"
                      persistent-hint
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                      v-model="itemFMaterialMap.toQty"
                      label="Mapping Quantity"
                      outlined
                      dense
                      hide-details
                      type="number"
                  ></v-text-field>
                </v-col>
              </v-row>

           </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down mr-5"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

    </v-dialog>
  </div>

</template>

<script>
import FMaterialMapService from '@/services/apiservices/f-material-map-service';

import CloseConfirmDialog from "@/components/utils/CloseConfirmDialog";
import FormMode from "@/models/form-mode";

export default {
  components: { CloseConfirmDialog },
  props:{
    formMode: String,

  },
  data() {
    return {
      title: 'Material Mapping',
      snackBarMesage: '',
      snackbar: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemsFDivision: [],

      valid: false,

      transparent: 'rgba(255, 255, 255, 0)',

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemFMaterialMap)
      return defaultItem !== modifiedItem
    },
    itemModified: {
      get () {
        // console.log(JSON.stringify(this.$store.state.material.itemModified))
        return this.$store.state.material.itemModified
      },
      set (value) {
        this.$store.dispatch('updateItemModified', value)
      }
    },

    itemFMaterialMap: {
      get () {
        return this.$store.state.material.itemFMaterialMap
      },
      set (value) {
        this.$store.dispatch('updateItemFMaterialMap', value)
      }
    },

    listFMaterial(){
      return this.$store.state.material.listFMaterial
    },

  },
  watch: {
  },

  methods: {

    showDialog(selectedIndex) {

      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(this.itemFMaterialMap)
      }else {
        this.selectedIndex = -1
      }

    },

    setDialogState(value){
      this.dialogShow =value
    },
    save(){
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }
      if (this.$refs.form.validate()){

        this.itemFMaterialMap.fmaterialBean = this.itemModified.id

        if (this.formMode===FormMode.EDIT_FORM) {
          FMaterialMapService.updateFMaterialMap(this.itemFMaterialMap).then(
              () => {
                // console.log(response.data)
                this.$emit('eventFromFormDialogEdit', this.itemFMaterialMap)
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }else {
          FMaterialMapService.createFMaterialMap(this.itemFMaterialMap).then(
              response =>{
                this.$emit('eventFromFormDialogNew', response.data)
              },
              error => {
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }
      }
    },

    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){

      this.formDialogOptions.errorMessage = ''

      FMaterialMapService.getFMaterialMapById(item.id).then(
          response =>{
            this.itemDefault = Object.assign({}, response.data)
            this.itemFMaterialMap = response.data
          },
          error =>{
            console.log(error)
          }
      )
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },
    lookupFMaterial (fmaterialBean) {
      const str = this.listFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },


  }

}
</script>

<style scoped>


</style>