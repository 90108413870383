<template>
  <v-container>
    <div class="mt-4">
      <v-card>
        <v-card-text>
          <v-container class="pa-4 text-center">
            <v-row
                class="fill-height"
                align="center"
                justify="center"
            >
              <v-col
                  v-for="(item, i) in itemsFileFiltered"
                  :key="i"
                  class="d-flex child-flex"
                  cols="4"
              >
                <template v-if="true">
                  <v-hover v-slot="{ hover }">
                    <v-card
                        class="align-self-center"
                        :elevation="hover ? 10 : 0"
                        :class="[{ 'on-hover': hover }, hover?'card-hover-opacity':'card-not-hover-opacity']"
                    >
<!--                      :lazy-src="lookupImageUrlLazy"-->
                      <v-img
                          :lazy-src="lookupImageUrlLazy(item)"
                          :src="lookupImageUrl(item)"
                      >
                        <v-card-title class="text-h6 white--text fill-height">

                          <v-row
                              class="fill-height flex-column"
                              justify="space-between"
                          >
                            <v-spacer/>
                            <div class="align-self-center">
                              <v-btn
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  icon
                                  large
                                  dark
                                  outlined
                                  @click="deleteDialogShow(item)"
                              >
                                <v-icon
                                    :class="{ 'show-btns': hover }"
                                    :color="transparent"
                                    large
                                >
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-row>

                        </v-card-title>
                      </v-img>
                    </v-card>
                  </v-hover>
                </template>

              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container class="text-center">
            <v-btn
                color="primary"
                block
                @click="showDialogUpload"
            ><v-icon>mdi-upload</v-icon> Upload </v-btn>
          </v-container>
        </v-card-actions>

      </v-card>

      <upload-image-dialog
          ref="refUploadDialog"
          @eventUploadSuccess="completeUploadSuccess"
      >
      </upload-image-dialog>

      <delete-confirm-dialog
          ref="refDeleteConfirmDialog"
          @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
          @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
      ></delete-confirm-dialog>

      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

    </div>

  </v-container>
</template>

<script>
import FMaterial from "../../../models/f-material"
import FMaterialFile from "../../../models/f-material-file";
import FileService from "../../../services/apiservices/file-service"
import FMaterialFileService from "../../../services/apiservices/f-material-file-service"

import UploadImageDialog from "../../utils/UploadImageDialog";
import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";

export default {
  components:{
    UploadImageDialog,
    DeleteConfirmDialog
  },
  props:{
    itemModified: new FMaterial(),
    itemsFile:[]
  },
  data() {
    return{
      snackbar: false,
      snackBarMesage: '',

      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  computed:{
    itemsFileFiltered(){
      // console.log(`Files on Gallery: ${JSON.stringify(this.itemsFtSalesdItems)}`)
      return this.itemsFile.filter(item => item.parentType===1 && item.fileType==="IMAGE")
      // return this.itemsFtSalesdItems
    }
  },
  methods: {
    showDialogUpload(){
      if (this.itemModified.pcode !==undefined &&
          this.itemModified.pname !==undefined &&
          this.itemModified.fdivisionBean !==undefined){

        // if (this.itemModified.id===0){
        // this.$emit('eventSaveItemWithoutClose', false)
        // console.log("masuk masih kosong")
        // }else {
        //   console.log("Sudah ada isinya")
        // }
        this.$refs.refUploadDialog.showDialog()

      }else{
        this.snackBarMesage = 'Deskripsi dan Divisi harus diisi dahulu'
        this.snackbar = true
      }

    },
    completeUploadSuccess: function (val){

      console.log(val)

      if (val.fileName !==""){

        let materialFile = new FMaterialFile(
            0,
            this.itemModified.id,
            val.fileName,

            val.description,
            val.description,

            "IMAGE",
            1,

            true,
            "Web FrontEnd"
        )
        materialFile.modifiedBy = this.$store.state.auth.user.username

        FMaterialFileService.createFMaterialFile(materialFile).then(
            response=>{
              // console.log(response)
              this.$refs.refUploadDialog.closeDialog()
              //Just REfresh files
              this.$emit('retrieveFiles', response.dummy)

            },
            error=>{
              console.log(error.message)
            }
        )
      }

    },

    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.itemsFile.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.description)
    },

    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.itemsFile[this.itemSelectedIndex]
      FMaterialFileService.deleteFMaterialFile(deletedItem.id).then(
          () => {
            this.itemsFile.splice(this.itemSelectedIndex, 1)
            this.closeDialog()
          },
          error => {
            console.log(error)
            this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
            this.snackbar = true
            this.$refs.refDeleteConfirmDialog.setDialogState(false)
          }
      )
      FileService.deleteImage(deletedItem.fileName).then(
          response=>{
            console.log(response.data)
          },
          error => {
            console.log(error.response)
          }
      )

    },
    deleteItemConfirmedMultiSelect(items){
      console.log(items)
    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refCutomerDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FMaterial())
        this.itemSelectedIndex = -1
      })
    },

    lookupImageUrl(item){
      console.log(item.fileName)
      if (item.fileName===undefined){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.fileName)
      }
    },
    // lookupImageUrlLazy(){
    //   return './assets/images/no_image_available.jpeg'
    // },
    lookupImageUrlLazy(item){
      return FileService.image_url_verylow(item.fileName)
    },

  }
}
</script>

<style scoped>

.card-hover-opacity{
  opacity: 0.4;
}
.card-not-hover-opacity{
  opacity: 1;
  /*transition: opacity .4s ease-in-out;*/
  transition: opacity .4s ease-in-out;
}

.show-btns {
  color: red !important;
  opacity: 1;
}

</style>