<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>

    </v-card-title>

    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fMaterialsFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
        align="start"
    >
      <template v-slot:top  v-if="false">
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        {{ lookupFDivision(item.fdivisionBean) }}
      </template>
      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ (index + ((currentPage-1)*pageSize) +1 )}}
      </template>

      <template v-slot:[`item.image`]="{item}">
        <v-img
          :lazy-src="lookupImageUrlLazy(item)"
          :src="lookupImageUrl(item)"
          width="90px"
          height="90px"
          class="ma-2 rounded"
        >
<!--          v-on:error="onImgError"-->
        </v-img>
      </template>


      <template v-slot:[`item.pname`]="{ item }" >
        <div class="fill-height">
          <div class="subtitle-2">{{ item.pname }}</div>
          <div class="blue--text">{{ item.pcode }}</div>
          <div class="mb-1" v-if="item.ref">ref *{{ item.ref }}</div>
          <div>
            <span v-if="item.convfact1>1">{{item.convfact1}}</span>
            <span v-if="item.convfact2>1">X{{item.convfact2}}</span>
            <span v-if="item.convfact3>1">X{{item.convfact3}}</span>
          </div>
          <div class="mt-2" v-if="item.statusActive===true">
            <v-icon color="green" small>mdi-checkbox-marked-circle</v-icon> Active
          </div>
          <div class="mt-2" v-else>
            <v-icon color="grey" small>mdi-stop-circle-outline</v-icon> <span class="grey--text caption">Non-Active</span>
          </div>

        </div>
      </template>

      <template v-slot:[`item.sprice2AfterPpn`]="{ item }">
        <div class="fill-height">
          Jual
          <div>
            <span class="subtitle-2">{{ formattedCurrencyValue('', item.sprice2AfterPpn) }}</span>
            <span class="ml-1 blue--text caption">{{ lookupFTax(item.ftaxBean).kode1 }}</span>
          </div>
          <div class="grey--text" v-if="haveRoleAdminMutlak">
            <span>{{ formattedCurrencyValue('', item.sprice2ToDist1) }}</span>
            |
            <span>{{ formattedCurrencyValue('', item.sprice2ToDist2) }}</span>
            |
            <span>{{ formattedCurrencyValue('', item.sprice2ToDist3) }}</span>
          </div>

          <div>
            <span class="mt-2 caption small" v-show="haveRoleAdmin">Beli</span>
            <span class="ml-1">{{ formattedCurrencyValue('', item.pprice2AfterPpn) }}</span>
          </div>

        </div>


      </template>


      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="fill-height">
          <div  class="text-caption small">
            {{ lookupFDivision(item.fdivisionBean) }}
          </div>
          <div class="mt-2">
            {{ lookupFMaterialGroup3(item.fmaterialGroup3Bean) }}
          </div>
          <div>
            {{ lookupFMaterialSalesBrand(item.fmaterialSalesBrandBean) }}
          </div>
        </div>
      </template>


      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteDialog>

    <FMaterialDialog :formMode.sync="formMode"
                     :itemsFDivision="itemsFDivision"

                     :itemsFMaterialSalesBrand="itemsFMaterialSalesBrand"
                     :itemsFMaterialGroup3="itemsFMaterialGroup3"
                     :itemsFVendor="itemsFVendor"
                     :itemsFWarehouse="itemsFWarehouse"
                     :itemsMaterialType="itemsMaterialType"
                     :itemsFTax="itemsFTax"
                  ref="refFormDialog"
                  @eventFromFormDialogNew="saveDataNew"
                  @eventFromFormDialogEdit="saveDataEdit"
    ></FMaterialDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FMaterialService from '../../../services/apiservices/f-material-service';
import FDivisionService from "../../../services/apiservices/f-division-service";
import FileService from "../../../services/apiservices/file-service"
import FMaterialGroup3Service from "../../../services/apiservices/f-material-group3-service";
import FMaterialSalesBrandService from "../../../services/apiservices/f-material-sales-brand-service"
import FVendorService from "../../../services/apiservices/f-vendor-service"
import FWarehouseService from "../../../services/apiservices/f-warehouse-service"
import FTaxService from "../../../services/apiservices/f-tax-service"


import DeleteDialog from "../../utils/DeleteConfirmDialog";
import FMaterialDialog from "./FMaterialDialog";
import FormMode from "../../../models/form-mode";
import FMaterial from '../../../models/f-material'
import FDivision from '../../../models/f-division'

import {format, parseISO} from "date-fns";


export default {
  components: { FMaterialDialog, DeleteDialog},
  data () {
    return {
      title: 'MATERIAL PRODUCT',

      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],
      imageBrokenLink: './assets/img_sample/sample-1.jpeg',

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'image',
          width: '20%'
        },
        { text: 'Deskripsi-Satuan', value: 'pname', width:'30%' },
        { text: 'Harga (Smallest)', value: 'sprice2AfterPpn', width: '20%' },
        { text: 'Klasifikasi', value: 'fdivisionBean',  width: '25%' },
        // { text: 'Actions', value: 'actions', align:'start', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fMaterials:[
          new FMaterial(1, "001")
      ],
      itemsFDivision: [
          new FDivision()
      ],
      itemsFMaterialSalesBrand: [],
      itemsFMaterialGroup3:[],
      itemsFVendor:[],
      itemsFWarehouse:[],
      itemsMaterialType:[],
      itemsFTax:[],


    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFMaterial()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          // console.log("Change PageSize " + value)
          value.toString()

          this.fetchFMaterial()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fMaterialsFiltered(){
      return this.fMaterials
    },
    haveRoleAdminMutlak(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleAdmin(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN") ||
            this.currentUser.roles.includes("ROLE_ADMIN1") ||
            this.currentUser.roles.includes("ROLE_ADMIN2")
        ){
          isValid=true
        }
      }
      return isValid
    },

  },
  methods: {

    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFMaterial()
      }
    },
    fetchParent(){
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }

      FMaterialSalesBrandService.getAllFMaterialSalesBrand().then(
          response =>{
            this.itemsFMaterialSalesBrand = response.data
            // console.log(`Hello sini ${this.itemsFMaterialSalesBrand}`)
          },
          error=>{
            console.log(error.response)
          }
      )
      FMaterialGroup3Service.getAllFMaterialGroup3().then(
          response =>{
            this.itemsFMaterialGroup3 = response.data
            // console.log(`Hello sini ${this.itemsFMaterialSalesBrand}`)
          },
          error=>{
            console.log(error.response)
          }
      )
      FVendorService.getAllFVendor().then(
          response =>{
            this.itemsFVendor = response.data
            // console.log(`Hello sini ${JSON.stringify(this.itemsFVendor)}`)
          },
          error=>{
            console.log(error.response)
          }
      )
      FWarehouseService.getAllFWarehouse().then(
          response =>{
            this.itemsFWarehouse = response.data
            // console.log(`Hello sini ${this.itemsFMaterialSalesBrand}`)
          },
          error=>{
            console.log(error.response)
          }
      )
      FTaxService.getAllFTax().then(
          response =>{
            this.itemsFTax = response.data
          },
          error =>{
            console.log(error.response)
          }
      )

      FMaterialService.getAllFMaterial().then(
          response=>{
            // console.log(JSON.stringify(response.data))
            this.$store.dispatch('updateListFMaterial', response.data)
          },
          error=>{
            this.$store.dispatch("updateListFMaterial", [])
            console.log(error)
          }
      )

    },
    fetchFMaterial() {
      // console.log(`Masuk fetchFMaterial`)
      FMaterialService.getAllFMaterialContaining(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
          response => {

            // console.log(JSON.stringify(response.data.items))
            JSON.stringify(response.data.items.map( item=>
                console.log(`warehouse ${item.fwarehouseBean}`)
            ))

            const { items, totalPages} = response.data
            this.fMaterials = items
            this.totalPaginationPages = totalPages

            console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            console.log(error.response)
            if (error.response.status===401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    showDialogNew() {
      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      // this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision, this.itemsMaterialType,
      //     this.itemsFMaterialSalesBrand, this.itemsFMaterialGroup3, this.itemsFVendor, this.itemsFWarehouse, this.itemsFPegawai)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fBeritas.push(this.itemSelected)
      // this.fBeritas = [itemFromRest].concat(this.fBeritas)
      // this.fBeritas.unshift(itemFromRest)
      this.fetchFMaterial()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fMaterialsFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      this.formMode = FormMode.EDIT_FORM
      // this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision,  this.itemsMaterialType,
      //     this.itemsFMaterialSalesBrand, this.itemsFMaterialGroup3, this.itemsFVendor, this.itemsFWarehouse, this.itemsFPegawai)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataEdit(item){
        this.itemSelected = item
        try {
          //Kalau Create terus uplaod image duluan tidak bisa
          Object.assign(this.fMaterials[this.itemSelectedIndex], this.itemSelected)
        }catch (e){
          this.fetchFMaterial()
          e.toString()
        }
      this.closeDialog()

    },


    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fMaterialsFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fMaterials[this.itemSelectedIndex]
      FMaterialService.deleteFMaterial(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fMaterials.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FMaterialService.deleteAllFMaterial(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.fMaterialsFiltered.indexOf(items[i])
                      this.fMaterials.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                    // console.log("Undeleted Items: " + response.data + " = " + items[i].id)

                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FMaterialService.deleteAllFMaterials()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FMaterial())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    lookupFMaterialGroup3 (fmaterialGroup3Bean) {
      const str = this.itemsFMaterialGroup3.filter(x => x.id===fmaterialGroup3Bean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },
    lookupFMaterialSalesBrand (fmaterialSalesBrandBean) {
      const str = this.itemsFMaterialSalesBrand.filter(x => x.id===fmaterialSalesBrandBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },
    lookupFTax (ftaxBean) {
      const str = this.itemsFTax.filter(x => x.id===ftaxBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      // return './assets/images/no_image_available.jpeg'
      return FileService.image_url_verylow(item.avatarImage)
    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()
      this.fetchFMaterial()

    }

  }

}
</script>

<style scoped>

</style>