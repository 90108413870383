<template>
  <v-container>

    <v-row class="blue-grey lighten-5">
      <v-col
          cols="12"
          sm="3"
          md="3"
      >
        <v-text-field
            v-model="itemModified.minQtyStok"
            label="Buffers Stock In Pcs"
            type="number"
            dense
        ></v-text-field>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import FMaterial from "@/models/f-material";
import FileService from "@/services/apiservices/file-service";

export default {
  name: "FDaftarKegiatanPejabat",
  props:{
    itemModified: new FMaterial(),
    itemsFDivision: [],
    itemsFPegawai:[],
  },
  methods:{
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    removeAutoCompleteKpa (item) {
      this.itemModified.kpaBean = null
      console.log(item)
      // const index = this.itemsFPegawai.indexOf(item.name)
      // if (index >= 0) this.itemsFPegawai.splice(index, 1)
    },
    removeAutoCompletePptk (item) {
      this.itemModified.pptkBean = null
      console.log(item)
      // const index = this.itemsFPegawai.indexOf(item.name)
      // if (index >= 0) this.itemsFPegawai.splice(index, 1)
    },
    removeAutoCompletePengawas (item) {
      this.itemModified.pengawasanBean = null
      console.log(item)
      // const index = this.itemsFPegawai.indexOf(item.name)
      // if (index >= 0) this.itemsFPegawai.splice(index, 1)
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },

  }
}
</script>

<style scoped>

</style>