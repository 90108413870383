<template>

  <div class="mt-4">

    <v-row>
      <v-col
          cols="12"
          md="12"
          sm="12"
      >
        <v-textarea
            v-model="itemModified.description"
            label="Deskripsi Product"
            counter
            outlined
            auto-grow
            dense
        >
        </v-textarea>

      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>

</template>

<script>
import FMaterial from "@/models/f-material"
export default {
  name: "FMaterialOlStoreDesc",
  components:{
  },
  props:{
    itemModified: new FMaterial('0'),
  },

  data() {
    return{
      snackbar: false,
      snackBarMesage: '',

      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>