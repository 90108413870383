const EMaterialType = Object.freeze({
    FERT: "FERT",
    NLAG: "NLAG",
    DIEN: "DIEN",
    ROH: "ROH",
    HALB: "HALB"
})

const EMaterialTypes = Object.freeze([
    {
        id: EMaterialType.FERT,
        description: 'Finished Products'
    },
    {
        id: EMaterialType.NLAG,
        description: '*Non-Stock Material'
    },
    {
        id: EMaterialType.DIEN,
        description: '*Services'
    },
    {
        id: EMaterialType.ROH,
        description: 'Row Materials'
    },
    {
        id: EMaterialType.HALB,
        description: 'Semi Finished Goods'
    },
])

export { EMaterialType as default, EMaterialTypes }
