<template>

  <v-card class="mt-2 ml-2 mr-2">
    <FMaterialTable ref="refTabMaterialClick"/>
  </v-card>

</template>

<script>
import FMaterialTable from "../../components/product/material/FMaterialTable";

export default {
  components: { FMaterialTable },
  data() {
    return {
    }
  },
  methods: {
    tabMaterialClick(){
      try {
        this.$refs.refTabMaterialClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },

  }
}
</script>

<style scoped>

</style>