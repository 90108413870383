<template>

  <v-container>

    <v-row>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-container class="text-center">
          <v-row
              class="fill-height"
              align="center"
              justify="center"
          >
            <template>
              <v-col
                  cols="12"
                  md="10"
                  sm="10"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                      rounded
                      :elevation="hover ? 10 : 1"
                      :class="[{ 'on-hover': hover }, hover?'card-hover-opacity':'card-not-hover-opacity']"
                      class="rounded-0"
                  >
                    <v-img
                        :lazy-src="lookupImageUrlLazy(itemModified)"
                        :src="lookupImageUrl(itemModified)"
                        class="rounded-0"
                    >
                      <v-card-title class="text-h6 white--text fill-height">

                        <v-row
                            class="fill-height flex-column"
                            justify="space-between"
                        >
                          <v-spacer/>
                          <div class="align-self-center">
                            <v-btn
                                :class="{ 'show-btns': hover }"
                                :color="transparent"
                                icon
                                large
                                dark
                                outlined
                                @click="showDialogUpload"
                            >
                              <v-icon
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  large
                              >
                                mdi-upload
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-row>

                      </v-card-title>
                    </v-img>

                  </v-card>
                </v-hover>
              </v-col>
            </template>
          </v-row>
        </v-container>

      </v-col>

      <v-col
          cols="12"
          sm="8"
          md="8"
      >

        <v-row>
          <v-col
              cols="12"
              sm="4"
              md="4"
          >
            <v-text-field
                v-model="itemModified.pcode"
                :rules="rulesLenght"
                label="Kode"
                dense
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
              md="6"
          >
            <v-text-field
                v-model="itemModified.pname"
                label="Nama Barang"
                :rules="rulesLenght"
                dense
                hide-details
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              sm="2"
              md="2"
          >
            <v-switch
                v-model="itemModified.statusActive"
                :label="itemModified.statusActive?'Aktif':'Non-Aktif'"
                x-small
                :disabled="! haveRoleAdminMutlak"
                dense
            ></v-switch>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
              cols="12"
              sm="12"
              md="12"
          >
            <v-card>
              <v-card-title v-if="false">
                <span class="red--text">Mapping Product</span>
                <v-spacer></v-spacer>
                <v-btn fab x-small><v-icon color="primary">mdi-plus</v-icon></v-btn>
              </v-card-title>
              <v-card-text>
                <v-data-table
                    v-if="false"
                    title="Mapping Product"
                    :headers="headers"
                ></v-data-table>

                <FMaterialTableMap
                ></FMaterialTableMap>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="6"
          sm="3"
          md="3"
      >
        <v-text-field
            v-model="itemModified.weightSmalest"
            label="Weight (Smallest) in Grams"
            type="number"
            dense
        ></v-text-field>
      </v-col>
      <v-col
          cols="6"
          sm="3"
          md="3"
      >
        <v-text-field
            v-model="itemModified.caseWeight"
            label="Weight (with Case) in Grams"
            type="number"
            dense
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="grey lighten-4">
      <v-col
          cols="12"
          sm="5"
          md="5"
      >
        <v-autocomplete
            v-model="itemModified.fdivisionBean"
            :items="itemsFDivision"
            :rules="rulesNotEmtpy"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Division/Branch"
            :hint="`Division = Branch/Distributor : ${lookupFDivision(itemModified.fdivisionBean)}`"
            persistent-hint
        ></v-autocomplete>

      </v-col>

      <v-col
          cols="12"
          sm="5"
          md="5"
      >
        <v-autocomplete
            v-model="itemModified.materialType"
            :items="getAllEmaterialType"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Material Type"
            persistent-hint
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row class="grey lighten-4">
      <v-col
          cols="12"
          sm="5"
          md="5"
      >
        <v-autocomplete
            v-model="itemModified.fmaterialGroup3Bean"
            :items="itemsFMaterialGroup3"
            item-value="id"
            item-text="description"
            :rules="rulesNotEmtpy"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Product Group"
            persistent-hint
        >
          <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="itemModified.fmaterialGroup3Bean=undefined"
            >
              <v-avatar left>
                <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrlLazy(data.item)"></v-img>
              </v-avatar>
              {{ data.item.description }}
              <!--              <span class="font-weight-light caption"> *{{ data.item.kode1 }} </span>-->
            </v-chip>
          </template>

          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrlLazy(data.item)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{data.item.description}} *{{data.item.kode1}}</v-list-item-title>
                <v-list-item-subtitle>
                  <span class="caption"> {{ data.item.kode1 }} </span>
                  <!--                  <span class="caption">{{lookupFDivisionNameOnly(data.item.fdivisionBean)}}</span>-->
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

      </v-col>

      <v-col
          cols="12"
          sm="5"
          md="5"
      >
        <v-autocomplete
            v-model="itemModified.fmaterialSalesBrandBean"
            :items="itemsFMaterialSalesBrand"
            item-value="id"
            item-text="description"
            :rules="rulesNotEmtpy"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Brand"
            persistent-hint
        >
          <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="itemModified.fmaterialSalesBrandBean=undefined"
            >
              <v-avatar left>
                <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrlLazy(data.item)"></v-img>
              </v-avatar>
              {{ data.item.description }}
              <span class="font-weight-light caption"> *{{ data.item.kode1 }} </span>
            </v-chip>
          </template>

          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrlLazy(data.item)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <!--?                          <v-list-item-title v-html="data.item.spname"></v-list-item-title>-->
                <v-list-item-title>{{data.item.description}} *{{data.item.kode1}}</v-list-item-title>
                <v-list-item-subtitle>
                  <span class="caption">{{lookupFDivisionNameOnly(data.item.fdivisionBean)}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

      </v-col>

    </v-row>

    <v-row class="grey lighten-4">
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-autocomplete
            v-model="itemModified.fvendorBean"
            :items="itemsFVendor"
            item-value="id"
            item-text="vname"
            :rules="rulesNotEmtpy"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Main Supplier"
            persistent-hint
        >
          <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="itemModified.fvendorBean=undefined"
            >
              <v-avatar left>
                <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrlLazy(data.item)"></v-img>
              </v-avatar>
              {{ data.item.vname }}
              <span class="font-weight-light caption"> *{{ data.item.vcode }} </span>
            </v-chip>
          </template>

          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrlLazy(data.item)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <!--?                          <v-list-item-title v-html="data.item.spname"></v-list-item-title>-->
                <v-list-item-title>{{data.item.vname}} *{{data.item.vcode}}</v-list-item-title>
                <v-list-item-subtitle>
                  <span class="caption">{{lookupFDivisionNameOnly(data.item.fdivisionBean)}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

      </v-col>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >

        <v-autocomplete
            v-model="itemModified.fwarehouseBean"
            :items="itemsFWarehouse"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            small-chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Main Warehouse"
            persistent-hint
        >
          <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="itemModified.fwarehouseBean=undefined"
            >
              <v-avatar left>
                <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrlLazy(data.item)"></v-img>
              </v-avatar>
              {{ data.item.description }}
              <span class="font-weight-light caption"> *{{ data.item.kode1 }} </span>
            </v-chip>
          </template>

          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <!--?                          <v-list-item-title v-html="data.item.spname"></v-list-item-title>-->
                <v-list-item-title>{{data.item.description}} *{{data.item.kode1}}</v-list-item-title>
                <v-list-item-subtitle>
                  <span class="caption">{{lookupFDivisionNameOnly(data.item.fdivisionBean)}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>

      </v-col>

    </v-row>

    <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
    >
    </UploadImageDialog>
    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>

</template>

<script>
import {format, parseISO} from "date-fns";
import FMaterial from "../../../models/f-material";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../utils/UploadImageDialog";
import FMaterialService from "../../../services/apiservices/f-material-service"
import FormMode from "../../../models/form-mode";
import {EMaterialTypes} from "../../../models/e-material-type";
import FMaterialTableMap from "@/components/product/material/FMaterialMapTable";

export default {
  components:{
    FMaterialTableMap,
    UploadImageDialog
  },
  props:{
    itemModified: new FMaterial(),

    itemsFDivision: [],
    itemsMaterialType:[],
    itemsFMaterialGroup3:[],
    itemsFMaterialSalesBrand:[],
    itemsFVendor:[],
    itemsFWarehouse:[],

  },
  data() {
    return{
      snackBarMesage: '',
      snackbar: false,

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      menuDate2: false,
      menuDate3: false,


      selectedItemIndex: -1,
      itemDefault: '',
      // itemModified: new FMaterial(0, '', ''),
      selectFDivision: {id: 0, kode1: '', description: '' },

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'image',
          width: '20%'
        },
        { text: 'Nama Brg', value: 'pname', width:'40%' },
        { text: 'Quantity', value: 'qty', width: '20%' },
        { text: 'Actions', value: 'actions', align:'start', sortable: false },
      ],


      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    haveRoleAdminMutlak(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_ADMIN")  ){
          isValid=true
        }
      }
      return isValid
    },

    getAllEmaterialType(){
      return EMaterialTypes
    },
  },
  methods:{
    showDialogUpload(){
      if (this.itemModified.pcode !==undefined &&
          this.itemModified.pname !==undefined &&
        this.itemModified.fdivisionBean !==undefined){

        if (this.itemModified.id===0){
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly()
        }
        this.$refs.refUploadDialog.showDialog()

      }else{
        this.snackBarMesage = 'Pcode, Pname dan Divisi harus diisi dahulu'
        this.snackbar = true
      }

    },
    completeUploadSuccess: function (val){

      // console.log(val)
      //Hapus Dulu yang lama
      // console.log(this.itemModified.avatarImage)
      if (this.itemModified.avatarImage !==undefined && this.itemModified.avatarImage !== '' ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
            response => {
              console.log(response.data)
            },
            error => {
              console.log(error.response)
            }
        )
      }

      if (val.fileName !==""){
        this.$refs.refUploadDialog.closeDialog()
        this.itemModified.avatarImage = val.fileName
        this.saveUpdateOnly()
        // this.save()
      }

    },
    saveCreateOnly(){
      FMaterialService.createFMaterial(this.itemModified).then(
          response =>{
            /**
             * dipaksa Save dan Update Dahulu
             */
            this.initializeEditMode(response.data)
            this.formMode = FormMode.EDIT_FORM
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    saveUpdateOnly(){
      FMaterialService.updateFMaterialAndFix(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },
    lookupFDivisionNameOnly (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description}`
      }else {
        return '-'
      }
    },


    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  }

}

</script>


<style scoped>
.card-hover-opacity{
  opacity: 0.4;
}
.card-not-hover-opacity{
  opacity: 1;
  transition: opacity .4s ease-in-out;
}

.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>