export default class FMaterialFile {
  constructor(
    id,
    fmaterialBean,
    fileName,

    title,
    description,

    fileType,
    parentType,

    flag,
    uploadFrom,

    created,
    modifiedBy
  ) {
    this.id = id;
    this.fmaterialBean = fmaterialBean;
    this.fileName = fileName;

    this.title = title;
    this.description = description;

    this.fileType = fileType;
    this.parentType = parentType;
    this.flag = flag;
    this.uploadFrom = uploadFrom;

    this.created = created;
    this.modifiedBy = modifiedBy;
  }

}
