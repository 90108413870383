<template>
  <v-container>
    <div class="ma-0">

      <v-data-table
        :headers="headers"
        :items="listFMaterialMap"
      >
        <template v-slot:top>
          <v-row align="center" class="ml-4 mr-4">
            <span class="subtitle-1 blue--text">Mapping Product</span>
            <v-spacer></v-spacer>
            <v-btn
                fab
                dark
                color="green"
                x-small
                @click="showDialogNew"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </template>

        <template v-slot:[`item.number`]="{ index }">
          {{index+1}}
        </template>

        <template v-slot:[`item.toMaterialBean`]="{ item }">
          <div>
            {{ lookupFMaterial(item.toMaterialBean).pname }}
          </div>
        </template>
        
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
              @click="showDialogEdit(item)"
              icon
          >
            <v-icon
                small
                color="warning"
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
              @click="deleteDialogShow(item)"
              icon
          >
            <v-icon
                small
                color="red accent-4"
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

    </v-data-table>

    </div>

    <FMaterialMapDialog
        :formMode.sync="formMode"
        :title="title"
        ref="refFormDialog"

        @eventFromFormDialogNew="saveDataNew"
        @eventFromFormDialogEdit="saveDataEdit"
    ></FMaterialMapDialog>

    <DeleteDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
    ></DeleteDialog>
  </v-container>
</template>

<script>

import FMaterialMapDialog from "./FMaterialMapTableDialog";
import FormMode from "@/models/form-mode";
import FMaterialMapService from "@/services/apiservices/f-material-map-service";
import DeleteDialog from "@/components/utils/DeleteConfirmDialog";

export default {
  components: {
    FMaterialMapDialog,
    DeleteDialog
  },
  props:{
    // itemModified: Object
  },
  data(){
    return{
      title: '',

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'to Material', value: 'toMaterialBean', width:'50%' },
        { text: 'Qty in Pcs', value: 'toQty', width:'20%' },
        { text: 'Actions', value: 'actions', align:'start', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
    }
  },
  watch:{
  },
  computed:{

    listFMaterialMap(){
      return this.$store.state.material.listFMaterialMap
    },

    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemFMaterialMap)
      return defaultItem !== modifiedItem
    },
    itemModified: {
      get () {
        return this.$store.state.material.itemModified
      },
      set (value) {
        this.$store.dispatch('updateItemModified', value)
      }
    },

    itemFMaterialMap: {
      get () {
        return this.$store.state.material.itemFMaterialMap
      },
      set (value) {
        this.$store.dispatch('updateItemFMaterialMap', value)
      }
    },

    listFMaterial(){
      return this.$store.state.material.listFMaterial
    },

  },
  methods: {

    deleteDialogShow (value) {
      this.itemSelectedIndex = this.listFMaterialMap.indexOf(value)
      this.itemValidCust = Object.assign({}, value)
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, this.itemValidCust.id )
    },
    deleteItemConfirmedSingleSelect(){
      const deletedItem = this.listFMaterialMap[this.itemSelectedIndex]
      FMaterialMapService.deleteFMaterialMap(deletedItem.id).then(
          () => {
            this.itemSelectedIndex = this.listFMaterialMap.indexOf(deletedItem)
            this.listFMaterialMap.splice(this.itemSelectedIndex, 1)

            this.closeDeleteDialog()
          },
          error => {
            console.log(error)
            this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
            this.snackbar = true
            this.$refs.refDeleteConfirmDialog.setDialogState(false)
          }
      )
    },
    showDialogNew() {
      this.itemSelectedIndex =-1
      const newItem = Object.assign({}, '')
      this.formMode = FormMode.NEW_FORM

      this.$store.dispatch("updateItemFMaterialMap", newItem)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex)
    },
    saveDataNew(itemFromRest){
      this.itemFMaterialMap = itemFromRest
      this.closeDialog()
      // this.$store.dispatch('aktifitaspromo/loadItemsValidCusts', this.itemModified.id ) //Jika menggunakan Pagination
      this.listFMaterialMap.push(this.itemFMaterialMap)

    },
    showDialogEdit (value) {
      this.itemSelectedIndex = this.listFMaterialMap.indexOf(value)
      const editedItem = Object.assign({}, value)
      this.formMode = FormMode.EDIT_FORM

      this.$store.dispatch('updateItemFMaterialMap', editedItem)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex)

    },
    saveDataEdit(itemFromRest){
      this.itemFMaterialMap = itemFromRest
      try {
        Object.assign(this.listFMaterialMap[this.itemSelectedIndex], this.itemFMaterialMap)
      }catch (e){
        e.return
      }
      this.closeDialog()
    },
    closeDialog () {
      this.formMode = ''
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemValidCust = Object.assign({}, '')
        this.itemSelectedIndex = -1
      })

    },
    closeDeleteDialog () {
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemValidCust = Object.assign({}, '')
        this.itemSelectedIndex = -1
      })
    },
    lookupFMaterial(fmaterialBean) {
      const str = this.listFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

  }

}
</script>

<style scoped>

</style>
